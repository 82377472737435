const config: any = {
  env: process.env.REACT_APP_MY_ENV || "dev",
  local: {
    apiEndpoints: {
      account: "http://localhost:8080/account/v1",
      content: "http://localhost:8080/content/v1",
      aggregate: "http://localhost:8080/aggregate/v1",
      asset: "http://localhost:8080/asset/v1",
      vendor: "http://localhost:8080/vendor/v1",
      report: "http://localhost:8080/report/v1",
      thirdparty: "http://localhost:8080/thirdparty/v1",
    },
  },
  dev: {
    apiEndpoints: {
      account: "https://careplansapidev.heartblr.org/account/v1",
      content: "https://careplansapidev.heartblr.org/content/v1",
      aggregate: "https://careplansapidev.heartblr.org/aggregate/v1",
      asset: "https://careplansapidev.heartblr.org/asset/v1",
      vendor: "https://careplansapidev.heartblr.org/vendor/v1",
      report: "https://careplansapidev.heartblr.org/report/v1",
      thirdparty: "https://careplansapidev.heartblr.org/thirdparty/v1",
    },
  },
  qa: {
    apiEndpoints: {
      account: "https://careplansapiqa.heartblr.org/account/v1",
      content: "https://careplansapiqa.heartblr.org/content/v1",
      aggregate: "https://careplansapiqa.heartblr.org/aggregate/v1",
      asset: "https://careplansapiqa.heartblr.org/asset/v1",
      vendor: "https://careplansapiqa.heartblr.org/vendor/v1",
      report: "https://careplansapiqa.heartblr.org/report/v1",
      thirdparty:"https://careplansapiqa.heartblr.org/thirdparty/v1"
    },
  },
  stg: {
    apiEndpoints: {
      account: "https://careplans-api-stg.heartblr.org/account/v1",
      content: "https://careplans-api-stg.heartblr.org/content/v1",
      aggregate: "https://careplans-api-stg.heartblr.org/aggregate/v1",
      asset: "https://careplans-api-stg.heartblr.org/asset/v1",
      vendor: "https://careplans-api-stg.heartblr.org/vendor/v1",
      report: "https://careplans-api-stg.heartblr.org/report/v1",
      thirdparty:"https://careplans-api-stg.heartblr.org/thirdparty/v1"
    },
  },
  preprod: {
    apiEndpoints: {
      account: "https://careplans-api-preprod.heartblr.org/account/v1",
      content: "https://careplans-api-preprod.heartblr.org/content/v1",
      aggregate: "https://careplans-api-preprod.heartblr.org/aggregate/v1",
      asset: "https://careplans-api-preprod.heartblr.org/asset/v1",
      vendor: "https://careplans-api-preprod.heartblr.org/vendor/v1",
      report: "https://careplans-api-preprod.heartblr.org/report/v1",
      thirdparty:"https://careplans-api-preprod.heartblr.org/thirdparty/v1"
    },
  },
  prod: {
    apiEndpoints: {
      account: "https://careplans-api.heart.org/account/v1",
      content: "https://careplans-api.heart.org/content/v1",
      aggregate: "https://careplans-api.heart.org/aggregate/v1",
      asset: "https://careplans-api.heart.org/asset/v1",
      vendor: "https://careplans-api.heart.org/vendor/v1",
      report: "https://careplans-api.heart.org/report/v1",
      thirdparty:"https://careplans-api.heart.org/thirdparty/v1"
    },
  }
};

export default config;
