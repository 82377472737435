import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VendorManagementState {
  pageNumber : number;
  search:string;
  pageSize:number;
  pageOrder:string;
  loader:boolean;


}

export const initialState: VendorManagementState = {
    pageNumber: 1,
    search : "",
    pageSize:25,
    pageOrder:"id desc",
    loader:false,
    
   
};

const assetManagementSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setPageNumber : (state,action:PayloadAction<number>)=>{
        state.pageNumber =  action.payload
    },
    searchAsset : (state,action:PayloadAction<string>)=>{
      state.pageNumber = 1;
      state.search =  action.payload
  },
  setPageSize: (state, action: PayloadAction<number>) => {
    state.pageSize = action.payload;
  },
  setPageOrder:(state,action)=>{
        state.pageOrder = action.payload;
  },
  clearVendorState : ()=>{
     return initialState
   },
   setLoader: (state, action: PayloadAction<boolean>) => {
    state.loader = action.payload;
  }
  },  
});
const { actions, reducer } = assetManagementSlice;

export const { setPageNumber ,setLoader,searchAsset,setPageSize,clearVendorState,setPageOrder} = actions;
export default reducer;
